/* eslint-disable react-hooks/exhaustive-deps */
/*global TradingView*/
/*eslint no-undef: "error"*/
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use'
import Datafeed from './datafeed';

const timeFrames = [
  {
    text: "1d",
    resolution: "1",
    description: "1 day",
  },
  {
    text: "5d",
    resolution: "5",
    description: "5 days",
  },
  {
    text: "1m",
    resolution: "120",
  },
  {
    text: "3m",
    resolution: "240",
  },
  {
    text: "1y",
    resolution: "1D",
  },
];


const TIMEZONE = {
  '-10': ['Pacific/Honolulu'],
  '-8': ['America/Anchorage', 'America/Juneau'],
  '-7': ['America/Los_Angeles', 'America/Phoenix', 'America/Vancouver'],
  '-6': ['America/Mexico_City'],
  '-5': ['America/Bogota', 'America/Chicago', 'America/Lima'],
  '-4': ['America/Caracas', 'America/New_York', 'America/Santiago', 'America/Toronto'],
  '-3': ['America/Argentina/Buenos_Aires', 'America/Sao_Paulo'],
  0: ['Atlantic/Reykjavik'],
  1: ['Africa/Casablanca', 'Africa/Lagos', 'Europe/London'],
  2: [
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Bratislava',
    'Europe/Brussels',
    'Europe/Budapest',
    'Europe/Copenhagen',
    'Africa/Johannesburg',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Rome',
    'Europe/Stockholm',
    'Europe/Warsaw',
    'Europe/Zurich',
  ],
  3: [
    'Asia/Bahrain',
    'Europe/Athens',
    'Europe/Bucharest',
    'Africa/Cairo',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Asia/Jerusalem',
    'Asia/Kuwait',
    'Europe/Moscow',
    'Asia/Nicosia',
    'Asia/Qatar',
    'Europe/Riga',
  ],
  4: ['Asia/Dubai'],
  5: ['Asia/Karachi'],
  6: ['Asia/Almaty'],
  6.5: ['Asia/Yangon'],
  7: ['Asia/Bangkok'],
  8: ['Asia/Chongqing'],
  9: ['Asia/Tokyo'],
  9.5: ['Australia/Adelaide'],
  10: ['Australia/Brisbane'],
  11: ['Pacific/Norfolk'],
  12.75: ['Pacific/Chatham'],
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Chart = (props) => {
  const [previousDataLength, setPreviousDataLength] = useState(0);
  const { symbol, interval, data, ethPrice } = props;
  const [, setWindowDimensions] = useState(null);
  const offset = (-1 * new Date().getTimezoneOffset()) / 60;
  const below600 = useMedia('(max-width: 600px)')
  const below800 = useMedia('(max-width: 800px)')

  const [isMobile, setIsMobile] = useState(false)
  const [, setIsBook] = useState(false)

  useEffect(() => {
    setWindowDimensions(getWindowDimensions())
  }, [])

  useEffect(() => {
    setIsMobile(below600)
  }, [below600])
  useEffect(() => {
    setIsBook(below800)
  }, [below800])

  const chartHeight = isMobile ? 400 : 400
  useEffect(() => {
    if (data && TradingView && data.length !== previousDataLength) {
      setPreviousDataLength(data.length)
      //eslint-ignore-next-line
      const widget = (window.tvWidget = new TradingView.widget({
        symbol: symbol,
        interval: interval,
        fullscreen: false,
        width: "100%",
        height: "100%",
        container: 'tv_chart_container',
        datafeed: Datafeed(data, ethPrice),
        library_path: '/charting_library/',
        toolbar_bg: '#0b1217',
        autosize: true,
        overrides: {
          'paneProperties.rightMargin': 0,
          'paneProperties.background': '#0b1217',
          'paneProperties.backgroundType': 'solid',
          'paneProperties.backgroundGradientEndColor': '#0b1217',
          'paneProperties.backgroundGradientStartColor': '#0b1217',
          'paneProperties.vertGridProperties.color': '#E3E3E5', // Grid Vertical Lines Color
          'paneProperties.horzGridProperties.color': '#E3E3E5', // Grid Horizontal Lines Color
          'mainSeriesProperties.candleStyle.upColor': '#11CC39', // Up Candle Color
          'mainSeriesProperties.candleStyle.downColor': '#E20E2C', // Down Candle Color
          'mainSeriesProperties.candleStyle.borderUpColor': '#11CC39', // Up Candle Border Color
          'mainSeriesProperties.candleStyle.borderDownColor': '#E20E2C', // Down Candle Border Color
          'mainSeriesProperties.candleStyle.drawBorder': false, // Disable candle borders
          'mainSeriesProperties.minTick': '100000000,1,false',
          "scalesProperties.textSize": 18,
          "scalesProperties.showLeftScale": false,
          "mainSeriesProperties.candleStyle.hollowCandle": true,
        },
        disabled_features: ['header_symbol_search'],
        time_frames: timeFrames,
        theme: 'Dark',
        timezone: TIMEZONE[offset][0],
      }));
      widget.onChartReady(async () => {

        const priceScale = widget.activeChart().getPanes()[0].getRightPriceScales()[0];
        priceScale.setMode(0);

        widget.chart().setVisibleRange({
          from: new Date().getTime() / 1000 - 12 * 60 * 60,
          to: new Date().getTime() / 1000 + 12 * 60 * 60
        })
        widget.activeChart().setTimezone('UTC');

        let priceList;
        let forecastData = [];
        for (let i = 0; i < data.length; i++) {
          let openPrice = Number(data[i].open) * ethPrice / (10 ** 12)
          let closePrice = Number(data[i].close) * ethPrice / (10 ** 12)
          let high = Math.max(openPrice, closePrice);
          let low = Math.min(openPrice, closePrice);
          priceList = { time: Number(data[i].time), open: openPrice, high: high, low, close: closePrice };
          forecastData.push(priceList);
        }
      });
    }
  }, [data]);
  return (
    <>
      <div id="tv_chart_container" style={{ height: chartHeight, backgroundColor: 'black' }} />
    </>
  );
};

export default Chart;
